import {
  AutocompleteElement,
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { CropperModal } from '../../../Common/ImageCropper';
import InputBase from '@mui/material/InputBase';
import Loading from 'react-fullscreen-loading';
import { callAxios } from '../../../../utils/axios';
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../../utils/toast';
import { useAppSelector } from '../../../../store/hooks';
import { useSearchParams } from 'react-router-dom';

type FormProps = {
  petCategory: string;
  name: string;
  serviceType: string;
  serviceTypeCheck: string;
  description: string;
  price: string;
  priceMedium: string;
  priceLarge: string;
  priceExtraLarge: string;
  time: number;
  topCategories: string[];
  primaryService: string[];
};

const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CreateService = ({ checked, setChecked }: any) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.user);

  const [petCategoryOptions, setPetCategoryOptions] = useState([]);
  const [primaryServices, setPrimaryServices] = useState([] as any);

  const [src, setSrc] = useState(null);
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  // handle Change
  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };


  const getServiceType = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        'dashboard/services/getServiceType?page=1&limit=100'
      );

      console.log(data);

      // setServiceTypeOptions(
      //   data?.data?.map((item: any) => {
      //     return {
      //       label: item.name,
      //       id: item._id,
      //     };
      //   })
      // );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };
  const getPrimaryServices = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        // "dashboard/services/getAllServices?page=1&limit=100&serviceTypeCheck=PRIMARY"
        `dashboard/services/getAllServices?page=1&limit=100&serviceTypeCheck=PRIMARY&vet=${user._id}`
      );

      console.log(data);

      setPrimaryServices(
        data?.data?.map((item: any) => {
          return {
            label: item.name,
            id: item._id,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  const getPetCategory = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        'app/pet/getPetCategory?page=1&limit=100'
      );

      console.log(data);

      setPetCategoryOptions(
        data?.data?.map((item: any) => {
          return {
            label: item.name,
            id: item._id,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  useEffect(() => {
    getServiceType();
    getPetCategory();
    getPrimaryServices();
  }, []);
  const defaultValues: FormProps = {
    petCategory: '',
    name: '',
    serviceType: '',
    serviceTypeCheck: '',
    description: '',
    price: '',
    priceMedium: '',
    priceLarge: '',
    priceExtraLarge: '',
    time: 0,
    topCategories: [],
    primaryService: [],
  };
  const [inputField, setInputField] = useState(defaultValues);
  const onInputChange = (e: any) => {
    console.log(e.target.value);

    const { name, value } = e.target;
    setInputField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onInputSelectChange = (name, value: any) => {
    setInputField((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = async (values: FormProps) => {
    if (!image) {
      return toastMessage('Please upload a profile picture', 'error');
    }
    if (values.time === 0) {
      return toastMessage('Please select time', 'error');
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', image);
      const { data: result } = await callAxios(
        'post',
        'app/user/uploadImage',
        formData
      );
      console.log(result);

      const payload = {
        ...values,
        images: [result?.url],
        vet: user._id,
        time: values.time * 60,
        topCategories: values.topCategories.map((item: any) => item?.id),
      };
      // console.log(payload);
      // debugger;
      // return;

      const { data } = await callAxios(
        'post',
        'dashboard/services/addService',
        payload
      );
      console.log(data);
      setChecked(!checked);
      setInputField(defaultValues);
      toastMessage('Service Created Successfully', 'success');
      setLoading(false);
      setSrc(null);
      setPreview(null);
      setImage('');
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <FormContainer
        // defaultValues={defaultValues} onSuccess={onSubmit}
        defaultValues={inputField}
        onSuccess={onSubmit}
        values={inputField}
        resetOptions={{
          keepValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepDirty: false,
          keepIsValid: false,
          keepSubmitCount: false,
        }}
        shouldFocusError={true}
      >
        <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col gap-4">
          <h6 className="text-tertiary publicSans text-base font-semibold ">
            Create New Service
          </h6>
          <div className="w-full grid grid-cols-3 gap-4">
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Pet Types
              </p>
              <SelectElement
                name="petCategory"
                value={inputField?.petCategory}
                onChange={(value) => onInputSelectChange('petCategory', value)}
                fullWidth
                size="small"
                placeholder="Center Name"
                sx={styles.root}
                options={petCategoryOptions}
                required
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Service Name
              </p>
              <TextFieldElement
                name="name"
                value={inputField?.name}
                onChange={onInputChange}
                fullWidth
                size="small"
                placeholder="Enter Service Name"
                sx={styles.root}
                required
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              {/* <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Service Type
              </p>
              <SelectElement
                name="serviceType"
                value={inputField?.serviceType}
                onChange={(value) => onInputSelectChange("serviceType", value)}
                fullWidth
                size="small"
                placeholder="Email"
                sx={styles.root}
                required
                options={serviceTypeOptions}
              /> */}
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-4">
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Service Types
              </p>
              <RadioButtonGroup
                onChange={(value) => {
                  setInputField((prev) => ({
                    ...prev,
                    serviceTypeCheck: value,
                  }));
                }}
                name="serviceTypeCheck"
                options={[
                  {
                    id: 'PRIMARY',
                    label: 'Primary',
                  },
                  {
                    id: 'SECONDARY',
                    label: 'Secondary',
                  },
                ]}
                row
                required
              />
            </div>
            {inputField?.serviceTypeCheck == 'SECONDARY' && (
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Choose Primary Service
                </p>
                <SelectElement
                  name="primaryService"
                  value={inputField?.primaryService}
                  onChange={(value) =>
                    onInputSelectChange('primaryService', value)
                  }
                  fullWidth
                  size="small"
                  placeholder="Email"
                  sx={styles.root}
                  required
                  options={primaryServices}
                />
                {/* <AutocompleteElement
                name="primaryService"
                required
                multiple
                autocompleteProps={{
                  sx: styles.root,
                  size: "small",
                }}
                options={services.map((el) => {
                  return {
                    id: el._id,
                    label: el.name,
                  };
                })}
              /> */}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1 w-full ">
            <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
              Service Description
            </p>
            <TextFieldElement
              name="description"
              value={inputField?.description}
              onChange={onInputChange}
              fullWidth
              size="small"
              placeholder="Enter Service Description"
              sx={styles.root}
              required
              multiline
              rows={4}
            />
          </div>
          <div className="w-full grid grid-cols-3 gap-4">
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Categories
              </p>
              <AutocompleteElement
                name="topCategories"
                required
                multiple
                autocompleteProps={{
                  sx: styles.root,
                  size: 'small',
                  onChange: (e, value) => {
                    setInputField((prev) => ({
                      ...prev,
                      topCategories: value,
                    }));
                  },
                }}
                options={[
                  { label: 'Instore Grooming', id: 'Instore Grooming' },
                  { label: 'Boarding', id: 'Boarding' },
                  { label: 'Daycare', id: 'Daycare' },
                  { label: 'Training', id: 'Training' },
                  { label: 'Pet Products', id: 'Pet Products' },
                  { label: 'Pet Taxi', id: 'Pet Taxi' },
                  { label: 'Mobile Grooming', id: 'Mobile Grooming' },
                ]}
              />
            </div>

            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Time
              </p>
              <SelectElement
                name="time"
                value={inputField?.time}
                onChange={(value) => onInputSelectChange('time', value)}
                fullWidth
                size="small"
                placeholder="Select Time"
                sx={styles.root}
                required
                options={[
                  { label: '0.5 Hour', id: 0.5 },
                  { label: '1 Hour', id: 1 },
                  { label: '1.5 Hour', id: 1.5 },
                  { label: '2 Hour', id: 2 },
                  { label: '2.5 Hour', id: 2.5 },
                  { label: '3 Hour', id: 3 },
                  { label: '3.5 Hour', id: 3.5 },
                  { label: '4 Hour', id: 4 },
                  { label: '4.5 Hour', id: 4.5 },
                  { label: '5 Hour', id: 5 },
                  { label: '5.5 Hour', id: 5.5 },
                  { label: '6 Hour', id: 6 },
                ]}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Upload Service Image
              </p>
              {preview ? (
                <>
                  <img src={preview} className='w-16 h-16'></img>
                                  <Button
                  className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                  component="label"
                  variant="contained"
                  color="secondary"
                >
                  Add Image
                  <VisuallyHiddenInput
                    type="file"
                    required
                    accept="image/*"
                    onChange={handleImgChange}
                  />
                </Button>
                  </>
              ) : (
                <Button
                  className="bg-[#F0F7FC] p-2 text-secondary publicSans font-semibold leading-5 "
                  component="label"
                  variant="contained"
                  color="secondary"
                >
                  Add Image
                  <VisuallyHiddenInput
                    type="file"
                    required
                    accept="image/*"
                    onChange={handleImgChange}
                  />
                </Button>
              )}
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-4">
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Service Price for Small
              </p>
              <TextFieldElement
                name="price"
                value={inputField?.price}
                onChange={onInputChange}
                fullWidth
                size="small"
                type="number"
                placeholder="Enter Service Price for Small"
                sx={styles.root}
                required
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Service Price for Medium
              </p>
              <TextFieldElement
                name="priceMedium"
                value={inputField?.priceMedium}
                onChange={onInputChange}
                fullWidth
                size="small"
                type="number"
                placeholder="Enter Service Price"
                sx={styles.root}
                required
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Service Price For Large
              </p>
              <TextFieldElement
                name="priceLarge"
                value={inputField?.priceLarge}
                onChange={onInputChange}
                fullWidth
                size="small"
                type="number"
                placeholder="Enter Service Price"
                sx={styles.root}
                required
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-4">
            <div className="w-full flex flex-col gap-1">
              <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                Service Price for Extra Large
              </p>
              <TextFieldElement
                name="priceExtraLarge"
                value={inputField?.priceExtraLarge}
                onChange={onInputChange}
                fullWidth
                size="small"
                type="number"
                placeholder="Enter Service Price"
                sx={styles.root}
                required
              />
            </div>
          </div>
          <div className="w-full flex justify-end pt-4">
            <button className="publicSans text-sm font-semibold leading-5 bg-[#EF8214] rounded-lg p-3 text-white">
              Create Service
            </button>
          </div>
        </div>
      </FormContainer>
            <CropperModal
        modalOpen={modalOpen}
        src={src}
        setImage={setImage}
        setPreview={setPreview}
        setModalOpen={setModalOpen}
      />
    </>
  );
};
export default CreateService;
