import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import AdoptionDetails from "./AdoptionDetails";
import DriverDetails from "../TaxiManagement/DriverDetails";
import Loading from "react-fullscreen-loading";
import VetDetails from "./VetDetails";
import { useSearchParams } from "react-router-dom";
import TaxiProviderDetails from "./TaxiProviderDetails";

const UserDetails = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex justify-between items-center">
          <div className="w-full flex flex-col gap-2">
            <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
              {searchParams.get("userType") === "DRIVER" ? 'Driver Management': 'User Management'}
            </h6>
            <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
              {searchParams.get("userType") === "DRIVER" ? 'Driver Management': 'User Management'}
            </p>
          </div>
          {searchParams.get("userType") === "ADOPTION" && (
            <div className="flex gap-4 items-center ">
              <button className=" w-full whitespace-nowrap py-2 px-3 rounded text-white bg-secondary text-sm publicSans font-semibold leading-5">
                Adoption History
              </button>
              <button
                className=" w-full whitespace-nowrap py-2 px-3 rounded text-white bg-[#EF8214] text-sm publicSans font-semibold leading-5"
                onClick={() =>
                  navigate(
                    `/admin/available-pets/${searchParams.get("userId")}`
                  )
                }
              >
                Available Pets
              </button>
            </div>
          )}
        </div>
        {searchParams.get("userType") === "ADOPTION" && <AdoptionDetails />}
        {searchParams.get("userType") === "VET" && <VetDetails />}
        {searchParams.get("userType") === "DRIVER" && <DriverDetails />}
        {searchParams.get("userType") === "TAXIPROVIDER" && <TaxiProviderDetails />}

      </div>
    </>
  );
};

export default UserDetails;
