import { Box, MenuItem, Select } from '@mui/material';
import {
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui';
import React, { useEffect, useState } from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loading from 'react-fullscreen-loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { callAxios } from '../../../../utils/axios';
import { toastMessage } from '../../../../utils/toast';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'white',
  borderRadius: '12px',
  border: '1px solid #fff',
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    width: '100%',
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
};

const AssignDriver = ({ setOpen, booking }: any) => {

const [loading, setLoading] = useState(false);
const [drivers, setDrivers] = useState([]);
const [selectedDriver, setSelectedDriver] = useState({});

const fetchDrivers = async () => {
  try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/driver/getAllDrivers?page=1&limit=0&activeDrivers=true`,
      );
      setDrivers(data.data);
      setLoading(false);
    } catch (error) {
      toastMessage('error', 'Failed to fetch drivers');
    }
}

useEffect(() => {
  fetchDrivers();
}, [])

const handleDriverChange = (e) => {
  setSelectedDriver(e.target.value);
  
}
const handleDriverSubmit = async () => {
  setLoading(true);
  try {
    if (!selectedDriver) {
      toastMessage('error', 'Please select a driver');
      return;
    }
    await callAxios('put', `dashboard/taxiBooking/updateBooking/${booking._id}`, {
      driverId: (selectedDriver as any)?._id
    });
    toastMessage('success', 'Driver assigned successfully');
    setOpen(false);
    fetchDrivers();
  } catch(err) {
    setLoading(false);
    toastMessage('error', err);
  }

}

console.log("======>>>>",selectedDriver)

  return (
    <>
    <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
    <Box sx={style}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormContainer>
          <div className="w-full bg-white flex flex-col gap-6 ">
            <div className="w-full justify-between flex items-center">
              <h6 className="publicSans text-xl leading-8 font-semibold text-blackTheme">
                Create Driver
              </h6>
              <img
                src="/assets/icons/cross.svg"
                alt=""
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="w-full gap-1">
              {/* Dropdown to select driver */}
              <Select
              name='selectedDriver'
              label="Select Driver"
              sx={styles.root}
              value={selectedDriver}
              onChange={handleDriverChange}
              >
                {drivers.map((driver) => (
                  <MenuItem key={driver._id} value={driver} className='cursor-pointer'>
                    {`${driver.username} (${driver.vehicleNumber})`}
                  </MenuItem>
                ))
              }

              </Select>

            </div>

            
            {/* CTA */}
            <div className="w-full justify-between items-center flex">
            {/* Cancel Button */}
              <button
                className="px-3 py-2 rounded-lg border border-[#858D9D] text-[#858D9D] publicSans text-sm font-semibold leading-5 cursor-pointer "
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              {/* Save Button */}
              <button 
              className="px-3 py-2 rounded-lg border border-secondary text-white bg-secondary publicSans text-sm font-semibold leading-5 cursor-pointer "
              onClick={handleDriverSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </FormContainer>
      </LocalizationProvider>
    </Box>
    </>
  );
};

export default AssignDriver;
