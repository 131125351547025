import { Box, Card, MenuItem, Select } from '@mui/material';
import { DatePickerElement, FormContainer } from 'react-hook-form-mui';
import { useEffect, useState } from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Loading from 'react-fullscreen-loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { callAxios } from '../../../utils/axios';
import { toastMessage } from '../../../utils/toast';
import { useAppSelector } from '../../../store/hooks';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'white',
  borderRadius: '12px',
  border: '1px solid #fff',
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
};

const CreateAppointment = ({ setOpen }: any) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state?.user);

  const [step, setStep] = useState(1);

  const [mainServices, setMainServices] = useState([]);
  const [selectedMainService, setSelectedMainService] = useState();
  const [secServices, setSecServices] = useState([]);
  const [selectedSecService, setSelectedSecService] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState();
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState({});
  const [pets, setPets] = useState([]);
  const [selectedPetId, setSelectedPetId] = useState();

  async function fetchServices() {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/services/getAllServices?page=1&limit=10&vet=${user?._id}`
      );
      console.log(data);

      setMainServices(data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  }

  function selectMainService(mainService) {
    setSelectedMainService(mainService);
    setSecServices(mainService.secondaryServices);
    console.log({ mainService });
  }

  async function fetchAvailableSlots() {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `app/services/getAvailableSlots?id=${
          (selectedMainService ?? { _id: '' })?._id
        }`
      );
      console.log(data);
      setLoading(false);
      setAvailableSlots(data?.data);
      console.log({ availableSlots });
    } catch (e) {
      console.error(e);
      setLoading(false);
      toastMessage(
        e?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  }

  async function createPaymentIntent() {
    try {
      if (selectMainService === undefined) {
        toastMessage('Please select a service', 'error');
        return;
      }

      if (selectedSlot === undefined) {
        toastMessage('Please select a slot', 'error');
        return;
      }

      if (selectedPetId === undefined) {
        toastMessage('Please select a pet', 'error');
        return;
      }

      const selectedServices = [selectedMainService, ...selectedSecService];
      const serviceIds = selectedServices.map((service) => service._id);
      const startTime = (selectedSlot ?? { startTime: '', endTime: '' })
        ?.startTime;
      const endTime = (selectedSlot ?? { startTime: '', endTime: '' })?.endTime;
      const date = new Date(selectedDate ?? Date.now());

      setLoading(true);
      const { data } = await callAxios(
        'post',
        `app/payment/createPaymentIntent`,
        {
          services: serviceIds,
          startTime: startTime,
          endTime: endTime,
          appointmentTimesamp: date.toISOString(),
          petId: selectedPetId,
          orderType: 'COD',
          // if (petTaxi != null)
          //   "petTaxi": {
          //     "street": petTaxi.street,
          //     "apt": petTaxi.apt,
          //     "city": petTaxi.city,
          //     "location": petTaxi.location.toJson(),
          //   },
        }
      );
      console.log(data);
      setLoading(false);
      setOpen(false);
      toastMessage('Appointment created successfully', 'info');
    } catch (e) {
      setLoading(false);
      toastMessage(
        e?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  }

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/dashUser/getAllAppUsers?page=1&limit=100000&userType='PETOWNER'`
      );
      console.log(data);

      setUsers(data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  const getAllPets = async (userId) => {
    console.log({ userId });
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/dashUser/getAllPets?page=1&limit=100000&user=${userId}`
      );
      console.log(data);
      setPets(data?.data);
      setLoading(false);
      console.log({ pets });
    } catch (e) {
      console.error(e);
      setLoading(false);
      toastMessage(
        e?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  useEffect(() => {
    fetchServices();
    getAllUsers();
  }, []);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <Box sx={style}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <FormContainer>
            <div className="w-full bg-white flex flex-col gap-6 ">
              <div className="w-full justify-between flex items-center">
                <h6 className="publicSans text-xl leading-8 font-semibold text-blackTheme">
                  Create Appointment
                  {/* <span className="text-tertiary">
                    {selectedMainService.price} */}
                </h6>
                <img
                  src="/assets/icons/cross.svg"
                  alt=""
                  className="cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              </div>

              {step === 1 && (
                <>
                  {/* Main Services Selection Cards */}
                  {/* Horizontal scrollable */}
                  <h2 className="publicSans leading-8 font-semibold text-blackTheme">
                    Main Services
                  </h2>
                  <div
                    style={{
                      width: 'auto',
                      overflowX: 'scroll',
                      display: 'flex',
                    }}
                  >
                    {mainServices.map((mainService) => (
                      <Card
                        className="p-2 m-2 gap-2 cursor-pointer w-36"
                        style={{
                          flexShrink: 0,
                          border:
                            (selectedMainService ?? { _id: '' })?._id ===
                            mainService._id
                              ? '1.5px solid #1F9CB9'
                              : '',
                        }}
                        onClick={() => selectMainService(mainService)}
                      >
                        <img src={mainService.profilePicture} alt="" />
                        <div className="w-full flex flex-col gap-2 justify-center">
                          <h6 className="publicSans text-sm font-semibold leading-5 text-blackTheme">
                            {mainService.name}
                          </h6>
                          <p className="publicSans text-sm leading-5 text-blackTheme">
                            {mainService.time} min
                          </p>
                          <p className="publicSans text-sm leading-5 text-info">
                            AED {mainService.price}
                          </p>
                        </div>
                      </Card>
                    ))}
                    h
                  </div>

                  {/* Secondary Services Selection Cards */}
                  <h2 className="publicSans leading-8 font-semibold text-blackTheme">
                    Secondary Services
                  </h2>
                  <div
                    style={{
                      width: 'auto',
                      overflowX: 'scroll',
                      display: 'flex',
                    }}
                  >
                    {selectedMainService &&
                      secServices.map((secService) => (
                        <Card
                          className="p-2 m-2 gap-2 cursor-pointer w-36"
                          style={{
                            flexShrink: 0,
                            border: selectedSecService.includes(secService._id)
                              ? '1.5px solid #1F9CB9'
                              : '',
                          }}
                          onClick={() => {
                            const selected = selectedSecService.includes(
                              secService._id
                            );
                            if (selected) {
                              setSelectedSecService(
                                selectedSecService.filter(
                                  (id) => id !== secService._id
                                )
                              );
                            } else {
                              setSelectedSecService([
                                ...selectedSecService,
                                secService._id,
                              ]);
                            }
                            console.log(selectedSecService);
                          }}
                        >
                          <img src={secService.profilePicture} alt="" />
                          <div className="w-full flex flex-col gap-2 justify-center">
                            <h6 className="publicSans text-sm font-semibold leading-5 text-blackTheme">
                              {secService.name}
                            </h6>
                            <p className="publicSans text-sm leading-5 text-blackTheme">
                              {secService.time} min
                            </p>
                            <p className="publicSans text-sm leading-5 text-info">
                              AED {secService.price}
                            </p>
                          </div>
                        </Card>
                      ))}
                  </div>
                </>
              )}

              {step === 2 && (
                <>
                  <h2 className="publicSans leading-8 font-semibold text-blackTheme">
                    Select Date
                  </h2>
                  <DatePickerElement
                    name="dob"
                    // placeholder="Ex-Labrador"
                    sx={styles.root}
                    inputProps={{
                      size: 'small',
                      fullWidth: true,
                    }}
                    required
                    onChange={(date) => {
                      setSelectedDate(date);
                      fetchAvailableSlots();
                      console.log(date);
                    }}
                  />
                  <h2 className="publicSans leading-8 font-semibold text-blackTheme">
                    Select Time Slot
                  </h2>
                  {availableSlots.length > 0 && (
                    <div
                      style={{
                        width: 'auto',
                        overflowX: 'scroll',
                        display: 'flex',
                      }}
                    >
                      {availableSlots.map((slot) => (
                        <Card
                          className="p-2 m-2 gap-2 cursor-pointer w-36"
                          style={{
                            flexShrink: 0,
                            border:
                              (selectedSlot ?? { startTime: '' }).startTime ===
                              slot.startTime
                                ? '1.5px solid #1F9CB9'
                                : '',
                          }}
                          onClick={() => {
                            setSelectedSlot(slot);
                          }}
                        >
                          <div className="w-full flex flex-col gap-2 justify-center">
                            <h6 className="publicSans text-sm font-semibold leading-5 text-blackTheme">
                              {slot.startTime} - {slot.endTime}
                            </h6>
                          </div>
                        </Card>
                      ))}
                    </div>
                  )}
                </>
              )}

              {step === 3 && (
                <>
                  <h2 className="publicSans leading-8 font-semibold text-blackTheme">
                    Select User
                  </h2>
                  <Select
                    name="selectedUser"
                    label="Select User"
                    sx={styles.root}
                    value={selectedUserId}
                    onChange={(event) => {
                      console.log(event.target.value);
                      setSelectedPetId(undefined);
                      setSelectedUserId(event.target.value);
                      getAllPets(event.target.value);
                    }}
                  >
                    {users.map((user) => (
                      <MenuItem
                        key={user._id}
                        value={user._id}
                        className="cursor-pointer"
                      >
                        {`${user.username} (${user.email})`}
                      </MenuItem>
                    ))}
                  </Select>
                  <h2 className="publicSans leading-8 font-semibold text-blackTheme">
                    Select Pets
                  </h2>

                  {pets.length > 0 ? (
                    <div
                      style={{
                        width: 'auto',
                        overflowX: 'scroll',
                        display: 'flex',
                      }}
                    >
                      {pets.map((pet) => (
                        <Card
                          className="p-2 m-2 gap-2 cursor-pointer w-36"
                          style={{
                            flexShrink: 0,
                            border:
                              selectedPetId === pet._id
                                ? '1.5px solid #1F9CB9'
                                : '',
                          }}
                          onClick={() => {
                            setSelectedPetId(pet._id);
                          }}
                        >
                          <div className="w-full flex flex-col gap-2 justify-center">
                            <h6 className="publicSans text-sm font-semibold leading-5 text-blackTheme">
                              {pet.name}
                            </h6>
                            <p className="publicSans text-sm leading-5 text-blackTheme">
                              {pet.breed}
                            </p>
                            <p className="publicSans text-sm leading-5 text-blackTheme">
                              {pet.age} Years Old
                            </p>
                            <p className="publicSans text-sm leading-5 text-blackTheme">
                              {pet.gender}
                            </p>
                            <p className="publicSans text-sm leading-5 text-blackTheme">
                              {pet.species}
                            </p>
                            <p className="publicSans text-sm leading-5 text-blackTheme">
                              {pet.size}
                            </p>
                          </div>
                        </Card>
                      ))}
                    </div>
                  ) : (
                    <p className="publicSans text-sm leading-5 text-blackTheme">
                      No pets added
                    </p>
                  )}
                </>
              )}

              {/* CTA */}
              <div className="w-full justify-between items-center flex">
                {/* Cancel Button */}
                <button
                  className="px-3 py-2 rounded-lg border border-[#858D9D] text-[#858D9D] publicSans text-sm font-semibold leading-5 cursor-pointer "
                  onClick={() => {
                    if (step === 1) {
                      setOpen(false);
                    } else {
                      setStep(step - 1);
                    }
                  }}
                >
                  {step === 1 ? 'Cancel' : 'Back'}
                </button>
                {/* Save Button */}
                <button
                  className="px-3 py-2 rounded-lg border border-secondary text-white bg-secondary publicSans text-sm font-semibold leading-5 cursor-pointer "
                  onClick={() => {
                    if (step === 3) {
                      createPaymentIntent();
                    } else {
                      setStep(step + 1);
                    }
                  }}
                >
                  {step === 3 ? 'Submit' : 'Next'}
                </button>
              </div>
            </div>
          </FormContainer>
        </LocalizationProvider>
      </Box>
    </>
  );
};

export default CreateAppointment;
