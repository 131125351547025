import React, { useState } from "react";

import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import Settings from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";
import { callAxios } from "../../utils/axios";
import { logout } from "../../store/slices/user";
import { useAppSelector } from "../../store/hooks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notifications, setNotifications] = React.useState([] as any);
  const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorNotificationEl, setAnchorNotificationEl] =
    React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorNotificationEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorNotificationEl(event.currentTarget);
  };
  const handleNotificationClose = async () => {
    setAnchorNotificationEl(null);

    try {
      const { data } = await callAxios(
        "put",
        "dashboard/dashNotifications/readNotifications",
        {}
      );
      console.log(data);
      setCount(0);
    } catch (error) {
      console.log(error);
    }
  };
  const { user } = useAppSelector((state) => state?.user);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const getNotifications = async () => {
    try {
      const { data } = await callAxios(
        "get",
        "dashboard/dashNotifications/getNotifications"
      );
      console.log(data);
      setNotifications(data.notifications);
      setCount(data.unreadCount);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getNotifications();
  }, []);

  const clearNotification = async () => {
   
    console.log("here");
    
    try {
      const { data } = await callAxios(
        "put",
        "dashboard/dashNotifications/clearAllNotifications",
        {}
      );
      console.log(data);
      getNotifications();
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div className="w-full sticky top-0 left-0 bg-primary px-6 py-2 flex justify-end items-center z-50">
      <div className=" flex gap-4 items-center">
        <div className="relative">
          {count !== 0 && (
            <div
              className={`absolute cursor-pointer  bg-[#F9B413] rounded -top-2 -right-1`}
              style={{
                padding: "2px 6px",
              }}
            >
              <p className="text-white inter text-[10px] font-semibold leading-4">
                {count}
              </p>
            </div>
          )}

          <div
            className="cursor-pointer"
            onClick={handleNotificationClick}
            aria-controls={openNotification ? "notification" : undefined}
            aria-haspopup="true"
            aria-expanded={openNotification ? "true" : undefined}
          >
            <img
              src="/assets/icons/notification.svg"
              alt="Notification"
              className="w-6"
            />
          </div>
        </div>
        <Menu
          anchorEl={anchorNotificationEl}
          id="notification"
          open={openNotification}
          onClose={handleNotificationClose}
              onClick={handleNotificationClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 120,
                height: 50,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div className="w-72 flex flex-col py-2 px-2 gap-3 items-center justify-center ">
            {notifications.map((notification: any) => (
              <div className="w-full flex flex-col gap-1 items-start cursor-pointer ">
                {/* <h6 className="text-base publicSans">{notification.title}</h6> */}
                <p className="text-sm gray-400 publicSans ">
                  {notification.description}
                </p>
                <hr />
                <div className="w-full flex justify-end">

                <button className="text-secondary publicSans float-right text-sm " onClick={clearNotification} >Clear All</button>
                </div>
              </div>
            ))}
          </div>
        </Menu>
        <Tooltip title="Account Settings">
          <div
            className="flex gap-3 items-center cursor-pointer "
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <div className="rounded-full relative">
              <img
                src="/assets/images/user-logo.png"
                alt="user logo"
                className="rounded-full"
              />
              <div className="absolute bg-green-500 rounded-full w-3 h-3 right-0 bottom-0 border border-white"></div>
            </div>
            <div className="flex flex-col gap-1 ">
              <p className="publicSans text-sm text-blackTheme font-medium leading-5">
                {user?.fullname}
              </p>
              <p className="publicSans text-xs leading-4 ">{user?.userType}</p>
            </div>
            <img src="/assets/icons/arrow-down.png" alt="" className="" />
          </div>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div className="w-full flex flex-col py-2 px-2 gap-3 items-center justify-center ">
            <div className="w-full flex gap-4 items-center cursor-pointer ">
              <Settings fontSize="small" />
              <p className="text-base publicSans ">Settings</p>
            </div>
            <div
              className="w-full flex gap-4 items-center cursor-pointer "
              onClick={handleLogout}
            >
              <Logout fontSize="small" />
              <p className="text-base publicSans "  >Logout</p>
            </div>
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
