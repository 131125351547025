export const adminRoutes = [
  {
    name: "User Management",
    path: "/admin/user-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Notifications",
    path: "/admin/notifications",
    icon: "/assets/icons/notifications.svg",
  },
  {
    name: "Account Creation",
    path: "/admin/account-creation",
    icon: "/assets/icons/account-creation.svg",
  },
  {
    name: "Pet Passport Database",
    path: "/admin/pet-passport-database",
    icon: "/assets/icons/pet-passport.svg",
  },
  {
    name: "Financial Management",
    path: "/admin/financial-management",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Product Category",
    path: "/admin/product-category",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Products",
    path: "/admin/products",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Orders",
    path: "/admin/orders",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Coupons",
    path: "/admin/coupons",
    icon: "/assets/icons/pet-passport.svg",
  },
];
export const adoptionRoutes = [
  {
    name: "Available Pets",
    path: "/adoption/available-pets",
    icon: "/assets/icons/available-pets.svg",
  },
  {
    name: "Adoption Center Profile",
    path: "/adoption/adoption-center-profile",
    icon: "/assets/icons/adoption-profile.svg",
  },
  {
    name: "Add a new pet",
    path: "/adoption/add-pet",
    icon: "/assets/icons/add-pet.svg",
  },
  {
    name: "Adoption History",
    path: "/adoption/adoption-history",
    icon: "/assets/icons/adoption-history.svg",
  },
];
export const vetRoutes = [
  {
    name: "Calendar",
    path: "/vet/calendar",
    icon: "/assets/icons/calendar.svg",
  },
  {
    name: "Profile",
    path: "/vet/profile",
    icon: "/assets/icons/vet-profile.svg",
  },
  {
    name: "Appointments",
    path: "/vet/appointments",
    icon: "/assets/icons/appointment.svg",
  },
  {
    name: "Driver Management",
    path: "/driver-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Taxi Management",
    path: "/taxi-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Financial Management",
    path: "/vet/financial-management",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Products",
    path: "/vet/products",
    icon: "/assets/icons/financial-management.svg",
  },
  {
    name: "Orders",
    path: "/vet/orders",
    icon: "/assets/icons/financial-management.svg",
  },
];
export const taxiProviderRoutes = [
  {
    name: "Profile",
    path: "/taxiProvider/profile",
    icon: "/assets/icons/vet-profile.svg",
  },
  {
    name: "Driver Management",
    path: "/driver-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Taxi Management",
    path: "/taxi-management",
    icon: "/assets/icons/user-management.svg",
  },
  {
    name: "Financial Management",
    path: "/vet/financial-management",
    icon: "/assets/icons/financial-management.svg",
  },
];
