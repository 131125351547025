import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Loading from "react-fullscreen-loading";
import axios from "axios";
import { backendUrl } from "../../utils/axios";
import { login } from "../../store/slices/user";
import { toastMessage } from "../../utils/toast";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";

type FormProps = {
  email: string;
  password: string;
};

const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#AEB4BE",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    backgroundColor: "#fff",
  },
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [values, setValues] = useState<FormProps>();
  const [loading, setLoading] = useState(false);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${backendUrl}/dashboard/auth/login`,
        values
      );
      console.log(data);
      dispatch(login(data));
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      setLoading(false);
      toastMessage("Login Successfully", "success");

      navigate(
        data.user.userType === "ADMIN"
          ? "/admin/user-management"
          : data.user.userType === "ADOPTION"
          ? "/adoption/available-pets"
          : data.user.userType === "VET"
          ? "/vet/calendar"
          : '/taxiProvider/profile',
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const defaultValues: FormProps = { email: "", password: "" };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full grid grid-cols-2 h-full p-8 bg-primary">
        <div className="flex w-full h-full relative"> 
          <div className="absolute rounded-xl bg-white p-6 bottom-4 right-4 ">
            <img src="/assets/images/logo.png" alt="logo" className="w-12 " />
          </div>
          <img
            src="/assets/images/login-bg.png"
            className="w-full h-full min-h-[90vh] rounded-lg"
            alt="login-bg"
          />
        </div>
        <div className="flex w-full items-center justify-center ">
          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="w-96 flex flex-col gap-8 items-center ">
              <div className="flex flex-col gap-3 items-center justify-center">
                <h5 className="publicSans text-2xl font-semibold leading-8 text-blackTheme">
                  Welcome Back !
                </h5>
                <p className="publicSans text-base font-normal leading-6 text-gray-500">
                  Welcome back, please enter your details.
                </p>
              </div>
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col gap-1 w-full">
                  <p className="text-tertiary font-medium text-sm leading-5">
                    Email
                  </p>
                  <TextFieldElement
                    name="email"
                    fullWidth
                    size="small"
                    placeholder="Your email"
                    sx={styles.root}
                    required
                  />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <div className="flex justify-between">
                    <p className="text-tertiary font-medium text-sm leading-5">
                      Password
                    </p>
                    <div onClick={()=>navigate("/forgot-password")} className="text-secondary cursor-pointer publicSans text-sm font-bold leading-5 ">
                      Forgot Password
                    </div>
                  </div>
                  <TextFieldElement
                    name="password"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Your password"
                    sx={styles.root}
                  />
                </div>
                <button
                  className="w-full rounded-lg bg-secondary py-2 flex items-center justify-center text-white leading-5 text-sm tracking-wide"
                  type="submit"
                >
                  Log in
                </button>
              </div>
            </div>
          </FormContainer>
        </div>
      </div>
    </>
  );
};

export default Login;
