import {
  AutocompleteElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";
import { styled } from "@mui/material/styles";
import { toastMessage } from "../../../utils/toast";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "white",
  borderRadius: "12px",
  border: "1px solid #fff",
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "14px",
      fontFamily: "Public Sans",
      fontWeight: 400,
    },
    backgroundColor: "#F9F9FC",
    borderRadius: "8px",
    color: "#858D9D !important",
  },
};

type FormProps = {
  name: string;
  type: string;
  percentageDiscount: number;
  vet: string;
  products: any[];
  services: any[];
};

const EditCoupon = ({ setOpen, checked, setChecked, existingData }: any) => {
  const [loading, setLoading] = useState(false);
  const [vets, setVets] = useState([] as any);

  const [type, setType] = useState(existingData?.type);
  const [vetId, setVetId] = useState(existingData?.vet?._id);
  const [vetProducts, setVetProducts] = useState([] as any);
  const [services, setServices] = useState([] as any);
    console.log(existingData);
    
  const [defaultValues, setDefaultValues] = useState({
    name: existingData?.name,
    type: existingData?.type,
    percentageDiscount: existingData?.percentageDiscount,
    vet: existingData?.vet?._id,
    products: existingData?.products?.map((product) => ({
      id: product._id,
      label: product.name,
    })),
    services: existingData?.services?.map((service) => ({
      id: service._id,
      label: service.name,
    })),
  });


  console.log(defaultValues);
  

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    if (values.type === "SERVICE") {
      values.products = [];
    } else {
      values.services = [];
    }

    try {
      setLoading(true);

      const payload = {
        ...values,
        products: values?.products?.map((product) => product.id),
        services: values?.services?.map((service) => service.id),
      };

      const { data } = await callAxios(
        "put",
        `dashboard/appConfig/updateCoupon/${existingData?._id}`,
        payload
      );
      console.log(data);
      setLoading(false);
      setOpen(false);
      setChecked(!checked);

      toastMessage("Coupon Created Successfully", "success");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const getAllVets = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", `dashboard/dashUser/getAllVets`);
      console.log(data);
      setVets(
        data?.data.map((vet) => {
          return {
            id: vet?._id,
            label: vet?.centerName,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllVets();
  }, []);

  console.log(type, vetId);

  const getAllServices = async (vetId: string) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/services/getServicesByVet/${vetId}`
      );
      console.log(data);
      setServices(
        data?.data.map((service) => {
          return {
            id: service?._id,
            label: service?.name,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  const getAllProducts = async (vetId: string) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `dashboard/products/getAllProductsByVet/${vetId}`
      );
      console.log(data);
      setVetProducts(
        data?.data.map((product) => {
          return {
            id: product?._id,
            label: product?.name,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  useEffect(() => {
    if (!vetId) return;

    if (!type) {
      return toastMessage("Please select type first", "error");
    }

    if (type === "SERVICE") {
      getAllServices(vetId);
    } else {
      getAllProducts(vetId);
    }
  }, [type, vetId]);

  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full bg-white flex flex-col gap-6 relative p-2 ">
        <div className="absolute rounded-full bg-[#F2F2F2] p-3 w-fit right-0 -top-2 ">
          <img
            src="/assets/icons/cross.svg"
            alt="close"
            className="cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <h5 className="publicSans text-xl font-semibold leading-7 text-center">
          Add Coupon
        </h5>
        <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
          <div className="w-full flex flex-col gap-4">
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Coupon Name
                </p>
                <TextFieldElement
                  name="name"
                  fullWidth
                  size="small"
                  placeholder="Enter Coupon Name"
                  sx={styles.root}
                  required
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Coupon Type
                </p>
                <SelectElement
                  name="type"
                  fullWidth
                  size="small"
                  required
                  placeholder="Center Name"
                  sx={styles.root}
                  options={[
                    { label: "Product", id: "PRODUCT" },
                    { label: "Service", id: "SERVICE" },
                  ]}
                  onChange={(e) => setType(e)}
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Vet
                </p>
                <SelectElement
                  name="vet"
                  fullWidth
                  size="small"
                  required
                  placeholder="Center Name"
                  sx={styles.root}
                  options={vets}
                  onChange={(e) => setVetId(e)}
                />
              </div>
              {type && type === "SERVICE" ? (
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Service
                  </p>
                  <AutocompleteElement
                    name="services"
                    multiple
                    options={services}
                    required
                    autocompleteProps={{
                      fullWidth: true,
                      sx: styles.root,
                      size: "small",
                      // placeholder: "Select Services",
                    }}
                  />
                </div>
              ) : (
                <div className="w-full flex flex-col gap-1">
                  <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                    Product
                  </p>
                  <AutocompleteElement
                    name="products"
                    multiple
                    options={vetProducts}
                    required
                    autocompleteProps={{
                      fullWidth: true,
                      sx: styles.root,
                      size: "small",
                      // placeholder: "Select Services",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col gap-1">
                <p className="text-sm publicSans font-medium leading-5 text-[#777980]">
                  Discount (in %)
                </p>
                <TextFieldElement
                  name="percentageDiscount"
                  fullWidth
                  type="number"
                  size="small"
                  placeholder="Enter Coupon Discount"
                  sx={styles.root}
                  required
                />
              </div>
            </div>
            <div className="w-full flex justify-end pt-4">
              <button
                className="publicSans text-sm font-semibold leading-5 bg-[#EF8214] rounded-lg p-3 text-white"
                type="submit"
              >
                Update Coupon
              </button>
            </div>
          </div>
        </FormContainer>
      </div>
    </Box>
  );
};

export default EditCoupon;
