import { Box, Card, CircularProgress } from '@mui/material';
import { DatePickerElement, FormContainer } from 'react-hook-form-mui';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { callAxios } from '../../../utils/axios';
import moment from 'moment';
import { toastMessage } from '../../../utils/toast';
import { useState } from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'white',
  borderRadius: '12px',
  border: '1px solid #fff',
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
};

const Resechdule = ({ setOpen, serviceId, bookingId }: any) => {
  const [loading, setLoading] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedSlot, setSelectedSlot] = useState();

  async function fetchAvailableSlots() {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `app/services/getAvailableSlots?id=${serviceId}`
      );
      console.log(data);
      setLoading(false);
      setAvailableSlots(data?.data);
      console.log({ availableSlots });
    } catch (e) {
      console.error(e);
      setLoading(false);
      toastMessage(
        e?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  }

  async function rescheduleBooking() {
    try {
      setLoading(true);
      await callAxios(
        'put',
        `app/payment/rescheduleAppointment?id=${bookingId}`,
        {
          /// utc format
          appointmentTimesamp: moment(selectedDate).format(),
          startTime: (selectedSlot as any).startTime,
          endTime: (selectedSlot as any).endTime,
          startDateTime: moment(selectedDate).format(),
        }
      );
      setLoading(false);
      toastMessage('Booking rescheduled successfully', 'success');
      setOpen(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      toastMessage(
        e?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  }

  return (
    <Box sx={style}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormContainer>
          <div className="w-full bg-white flex flex-col gap-6 ">
            <div className="w-full justify-between flex items-center">
              <h6 className="publicSans text-xl leading-8 font-semibold text-blackTheme">
                Reschedule
              </h6>
              <img
                src="/assets/icons/cross.svg"
                alt=""
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <p className="text-[#777980] publicSans text-sm font-medium leading-4">
                Select Date
              </p>
              <DatePickerElement
                name="date"
                sx={styles.root}
                required
                disablePast
                format='DD/MM/YYYY'
                onChange={(date) => {
                  setSelectedDate(date);
                  fetchAvailableSlots();
                  console.log(date);
                }}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <h2 className="publicSans leading-8 font-semibold text-blackTheme">
                Select Time Slot
              </h2>
              {}
              {loading ? (
                <CircularProgress color="primary" />
              ) : (
                availableSlots.length > 0 && (
                  <div
                    style={{
                      width: 'auto',
                      overflowX: 'scroll',
                      display: 'flex',
                    }}
                  >
                    {availableSlots.map((slot) => (
                      <Card
                        className="p-2 m-2 gap-2 cursor-pointer w-36"
                        style={{
                          flexShrink: 0,
                          border:
                            (selectedSlot ?? { startTime: '' }).startTime ===
                            slot.startTime
                              ? '1.5px solid #1F9CB9'
                              : '',
                        }}
                        onClick={() => {
                          setSelectedSlot(slot);
                        }}
                      >
                        <div className="w-full flex flex-col gap-2 justify-center">
                          <h6 className="publicSans text-sm font-semibold leading-5 text-blackTheme">
                            {slot.startTime} - {slot.endTime}
                          </h6>
                        </div>
                      </Card>
                    ))}
                  </div>
                )
              )}
            </div>
            <div className="w-full justify-between items-center flex">
              <button
                className="px-3 py-2 rounded-lg border border-[#858D9D] text-[#858D9D] publicSans text-sm font-semibold leading-5 cursor-pointer "
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </button>

              <button
                className="px-3 py-2 rounded-lg border border-secondary text-white bg-secondary publicSans text-sm font-semibold leading-5 cursor-pointer "
                onClick={rescheduleBooking}
              >
                {loading ? <CircularProgress size={14} /> : 'Save'}
              </button>
            </div>
          </div>
        </FormContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default Resechdule;
