import { Box, IconButton, InputAdornment } from '@mui/material';
import {
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { callAxios } from '../../../../utils/axios';
import { toastMessage } from '../../../../utils/toast';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'white',
  borderRadius: '12px',
  border: '1px solid #fff',
  p: 2,
  // boxShadow: 24,
  // p: 4,
};

const styles = {
  root: {
    '& .MuiInputBase-input:-webkit-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input:-moz-autofill-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::-ms-input-placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#858D9D',
      backgroundColor: '#fff',
      fontSize: '14px',
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    backgroundColor: '#F9F9FC',
    borderRadius: '8px',
    color: '#858D9D !important',
  },
};

const CreateDriver = ({ setOpen }: any) => {

const [email, setEmail] = useState('');
const [countryCode, setcountryCode] = useState('');
const [phone, setPhone] = useState('');
const [vehicleNumber, setvehicleNumber] = useState('');
const [username, setusername] = useState('');
const [password, setpassword] = useState('');
const [showPassword, setShowPassword] = useState(false);
const handlePhoneChange = (e) => {
  setPhone(e.target.value);
}
const handleEmailChange = (e) => {
  setEmail(e.target.value);
}
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
const handleSubmit = () => {
  if (!email || !phone || !vehicleNumber || !username || !password){
    toastMessage('All fields are required', 'error')
    return
  }
  callAxios(
    'post',
    'dashboard/driver/addDriver',
    {
      username,
      email,
      phone,
      vehicleNumber,
      password,
      countryCode,
    }
  )
    .then(() => {
      toastMessage('Driver created successfully', 'success')
      setOpen(false)
    })
    .catch(err => {
      console.log(err)
      toastMessage(err[0], 'error')
    })
}


  return (
    <Box sx={style}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormContainer>
          <div className="w-full bg-white flex flex-col gap-6 ">
            <div className="w-full justify-between flex items-center">
              <h6 className="publicSans text-xl leading-8 font-semibold text-blackTheme">
                Create Driver
              </h6>
              <img
                src="/assets/icons/cross.svg"
                alt=""
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="w-full flex flex-row gap-1">
            {/* Country Code */}
            <div className="w-1/4 gap-1">
              <p className="text-[#777980] publicSans text-sm font-medium leading-4">
                Country Code:
              </p>
              <TextFieldElement
                name="countryCode"
                value={countryCode}
                placeholder=".."
                required
                size="small"
                sx={styles.root}
                onChange={(e) => setcountryCode(e.target.value)}
              />
            </div>
            {/* Phone No */}
            <div className="w-3/4 gap-1">
              <p className="text-[#777980] publicSans text-sm font-medium leading-4">
                Phone No:
              </p>
              <TextFieldElement
                name="phone"
                placeholder="Enter Phone Number"
                required
                size="small"
                sx={styles.root}
                value={phone}
                onChange={handlePhoneChange}
                type="number"
                fullWidth
                validation={{
                  validate: (value) => {
                    let regex = /^[1-9]\d+$/;
                    if (regex.test(value)) {
                      return true;
                    } else {
                      return 'The phone number should not start with zero. Please enter a valid phone number';
                    }
                  },
                }}
              />
            </div>
            </div>

            {/* Vehicle Number */}
            <div className="w-full flex flex-col gap-1">
              <p className="text-[#777980] publicSans text-sm font-medium leading-4">Vehicle Number:</p>
              <TextFieldElement
              name="vehicleNumber"
              placeholder="Enter Vehicle Number"
              size="small"
              value={vehicleNumber}
              onChange={(e) => setvehicleNumber(e.target.value)}
              required
              fullWidth
              sx={styles.root}
              />
            </div>

            {/* Email */}
            <div className="w-full flex flex-col gap-1">
              <p className="text-[#777980] publicSans text-sm font-medium leading-4">
                Email
              </p>
              <TextFieldElement
              value={email}
              name="email"
              placeholder="Enter Email"  
              size="small"
              onChange={handleEmailChange}
              sx={styles.root}
              validation= {{
                validate: (value) => {
                  let regex = /^\S+@\S+$/;
                  if(regex.test(value)) {
                    return true;
                  } else {
                    return 'Please enter a valid email address';
                  }
                }
              }}
              fullWidth />
            </div>
            {/* Username */}
            <div className="w-full flex flex-col gap-1">
              <p className="text-[#777980] publicSans text-sm font-medium leading-4">
                Username
              </p>
              <TextFieldElement
              name="username"
              value={username}
              placeholder="Enter Username"
              required
              size="small"
              onChange={(e) => setusername(e.target.value)}
              sx={styles.root}
              fullWidth
              />
            </div>
            {/* Password */}
            <div className="w-full flex flex-col gap-1">
              <p className="text-[#777980] publicSans text-sm font-medium leading-4">
                Password
              </p>
              <TextFieldElement
              name="password"
              value={password}
              type={showPassword ? "text" : "password"}
              hidden={showPassword}
              placeholder="Enter Password"
              size="small"
              onChange={(e) => setpassword(e.target.value)}
              required
              sx={styles.root}
              fullWidth
              InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff fontSize="small" />
                  ) : (
                    <Visibility fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
              )
            }}
            />
            </div>

            
            {/* CTA */}
            <div className="w-full justify-between items-center flex">
            {/* Cancel Button */}
              <button
                className="px-3 py-2 rounded-lg border border-[#858D9D] text-[#858D9D] publicSans text-sm font-semibold leading-5 cursor-pointer "
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </button>
              {/* Save Button */}
              <button 
              className="px-3 py-2 rounded-lg border border-secondary text-white bg-secondary publicSans text-sm font-semibold leading-5 cursor-pointer "
              onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </FormContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default CreateDriver;
