import React, { useEffect, useState } from "react";

import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";

import { backendUrl } from "../../utils/axios";
import OtpInput from "react-otp-input";
const VerifyOtp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(searchParams.get("token") || "");

  const [timeLeft, setTimeLeft] = useState(60);
  const [timerExpired, setTimerExpired] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);

  const handleSubmit = async () => {
    console.log(otp);
    console.log(token);

    if (!otp) {
      toastMessage("Please Enter OTP", "error");
      return;
    } else if (otp.length < 4) {
      toastMessage("Please Enter valid OTP", "error");
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${backendUrl}/dashboard/auth/verifyOtp`,
        {
          otp: Number(otp),
          token,
        }
      );
      console.log(data);
      setLoading(false);
      toastMessage(data.message, "success");
      setTimerStarted(true);
      navigate(`/reset-password?token=${token}`);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    let timerInterval;

    if (timeLeft > 0) {
      timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000); // Update the timer every 1 second
    } else {
      setTimerExpired(true);
    }

    return () => {
      clearInterval(timerInterval); // Clean up the timer interval when component unmounts
    };
  }, [timeLeft]);

  const formattedTimeLeft = timeLeft.toString().padStart(2, "0");

  const resendOtp = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${backendUrl}/dashboard/auth/resendOtp`,
        {
          token,
        }
      );
      console.log(data);
      setLoading(false);
      toastMessage(data.message, "success");
      setTimerStarted(true);
      setTimerExpired(false);
      setTimeLeft(60);
      setToken(data.token);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full grid grid-cols-2 h-full p-8 bg-primary">
        <div className="flex w-full h-full relative">
          <div className="absolute rounded-xl bg-white p-6 bottom-4 right-4 ">
            <img src="/assets/images/logo.png" alt="logo" className="w-12 " />
          </div>
          <img
            src="/assets/images/login-bg.png"
            className="w-full h-full min-h-[90vh] rounded-lg"
            alt="login-bg"
          />
        </div>
        <div className="flex w-full items-center justify-center ">
          <div className="w-96 flex flex-col gap-8 ">
            <div className="flex flex-col gap-3">
              <h2 className="publicSans text-2xl font-semibold leading-8 text-blackTheme ">
                Enter OTP
              </h2>
              <p className="publicSans text-base font-normal leading-6 text-gray-500 ">
                We have mailed you an OTP on your email address.
              </p>
            </div>
            <div className="flex flex-col gap-4 w-full">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                containerStyle={{
                  display: "flex",
                  gap: "1rem",
                  width: "100%",
                }}
                inputStyle={{
                  width: "2.5rem",
                  height: "3rem",
                  borderRadius: "6px",
                  border: "1px solid #8692A6",
                  padding: "0.5rem",
                  fontSize: "1.5rem",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  color: "#000",
                }}
                inputType="tel"
              />
            </div>
            <div className="flex flex-col gap-4">
              <button
                className="w-full rounded-lg bg-secondary py-2 flex items-center justify-center text-white leading-5 text-sm tracking-wide"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <div className="flex w-full justify-between items-center ">
                <p
                  className={`poppins text-lg leading-8 tracking-tight  ${
                    timerExpired
                      ? "cursor-pointer"
                      : "cursor-not-allowed text-gray-400"
                  }   `}
                  onClick={timerExpired ? resendOtp : () => {}}
                >
                  Resend Otp
                </p>

                <p className="text-[#2186F2] poppins text-lg leading-8 tracking-tight ">
                  00:{formattedTimeLeft}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
