import { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import ListofNotifications from './ListofNotifications';
import ScheduleInputNotification from './ScheduleInputNotification';
import { callAxios } from '../../../utils/axios';
// Table Imports
import { toastMessage } from '../../../utils/toast';

const Notifications = () => {
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [allNotifications, setAllNotifications] = useState([]);

  const getAllNotification = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/notifications/getNotifications?search=${searchText}`
      );
      console.log(data);
      setAllNotifications(data.data);

      //   setTotalPages(data.totalPages);
      //   setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastMessage(error.message, 'error');
    }
  };

  useEffect(() => {
    getAllNotification();
  }, [searchText]);
  return (
    <>
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Notification
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Manage Notification
          </p>
        </div>
        <div className="w-full bg-white rounded-lg shadow p-4 px-8 flex justify-between gap-2">
          <div className="w-1/2 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
            <img src="/assets/icons/search.svg" alt="" className="" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-2/5 h-72 flex flex-col py-2 gap-2 px-2 ">
            <ScheduleInputNotification
              setAllNotifications={setAllNotifications}
            />
          </div>
          <div className="w-3/5 flex flex-col py-2 gap-2 px-2">
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <ListofNotifications
                allNotifications={allNotifications}
                setAllNotifications={setAllNotifications}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
