import React, { useState } from "react";

import AdoptionCreation from "./AdoptionCreation";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import NativeSelect from "@mui/material/NativeSelect";
import Select from "@mui/material/Select";
import TaxiProviderCreation from "./TaxiProviderCreation";
import VetCreation from "./VetCreation";
import { styled } from "@mui/material/styles";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    position: "relative",
    backgroundColor: "#F9F9FC",
    fontFamily: "Public Sans",
    border: "1px solid #E0E2E7",
    paddingLeft: "10px",
    fontSize: "14px",
  },
}));

const AccountCreation = () => {
  const [userType, setUserType] = useState("");

  return (
    <div className="w-full flex flex-col gap-6 ">
      <div className="w-full flex flex-col gap-2">
        <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
          Account Creation
        </h6>
        <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
          Create Account
        </p>
      </div>
      <div className="w-full bg-white rounded-lg shadow p-4 flex flex-col gap-2">
        <p className="text-tertiary publicSans font-semibold text-lg leading-6">
          Select User Type
        </p>
        <Select
          value={userType}
          onChange={(e) => setUserType(e.target.value)}
          displayEmpty
          input={<Input />}
        >
          <MenuItem value="" disabled>
            Select Type
          </MenuItem>
          <MenuItem value={"VET"}>Vet</MenuItem>
          <MenuItem value={"ADOPTION"}>Adoption</MenuItem>
          <MenuItem value={"TAXIPROVIDER"}>Taxi Provider</MenuItem>
        </Select>
      </div>
      {userType === "VET" && <VetCreation />}
      {userType === "ADOPTION" && <AdoptionCreation />}
      {userType === "TAXIPROVIDER" && <TaxiProviderCreation />}
    </div>
  );
};

export default AccountCreation;
