import React, { useEffect, useState } from 'react';

import AddCoupon from './AddCoupon';
import { CircularProgress } from '@mui/material';
import EditCoupon from './EditCoupon';
import Loading from 'react-fullscreen-loading';
import Modal from '@mui/material/Modal';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { callAxios } from '../../../utils/axios';
// Table Imports
import { styled } from '@mui/material/styles';
import { toastMessage } from '../../../utils/toast';

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontWeight: 500, // Customize cell text styling
  fontFamily: 'Public Sans',
  fontSize: '14px',
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#1D1F2C',
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#fff', // Customize cell background color
  fontFamily: 'Public Sans',
  fontSize: '14px',
  fontWeight: 500, // Customize cell text styling
  lineHeight: '20px',
  borderBottom: 'none',
  color: '#667085',
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#fff', // Customize table head row background color
  border: 'none',
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: '10px',
}));

const CouponsTable = () => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [dataRange, setDataRange] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [addCouponModal, setAddCouponModal] = useState(false);
  const [editCouponModal, setEditCouponModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState({} as any);
  const [checked, setChecked] = useState(false);

  const getAllCategory = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        'get',
        `dashboard/appConfig/getAllCoupon?page=${page}&limit=10&search=${searchText}`
      );
      console.log(data);

      setCoupons(data?.data);
      setCount(data?.totalData);
      setTotalPages(data?.totalPage);
      setDataRange(
        'Showing' +
          (data?.currentPage * 10 - 9) +
          '-' +
          data?.currentPage * 10 +
          ' of ' +
          data?.totalData
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      toastMessage(
        error?.response?.data?.message || 'Something went wrong',
        'error'
      );
    }
  };

  useEffect(() => {
    getAllCategory();
  }, [page, searchText, checked]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#1F9CB9" />
      <div className="w-full flex flex-col gap-6 ">
        <div className="w-full flex flex-col gap-2">
          <h6 className="publicSans text-2xl font-semibold leading-8 tracking-wide text-tertiary">
            Coupons
          </h6>
          <p className="text-secondary text-sm leading-5 tracking-wide publicSans font-medium">
            Coupons
          </p>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between bg-white rounded-lg shadow p-4 px-8 ">
        <div className="w-10/12 flex items-center rounded-full bg-[#F9F9F9] p-2 px-4 ">
          <img src="/assets/icons/search.svg" alt="" className="" />
          <input
            type="text"
            placeholder="Search"
            className="w-full bg-transparent outline-none ml-2 text-gray-500 text-sm "
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="flex  justify-center items-center">
          <button
            className="bg-cyan-800 text-white border-cyan-800 border-2 border-solid rounded-3xl px-6 py-2"
            onClick={() => setAddCouponModal(true)}
          >
            Add Coupon
          </button>
        </div>
      </div>
      <div className="w-full bg-white rounded-lg shadow flex flex-col py-2 gap-6 px-4 ">
        <div className="w-full">
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <Table
              sx={{
                borderCollapse: 'separate !important',
                width: '100%',
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>Name</CustomTableHeadCell>
                  <CustomTableHeadCell>Type</CustomTableHeadCell>
                  <CustomTableHeadCell>Discount</CustomTableHeadCell>
                  <CustomTableHeadCell>Vet</CustomTableHeadCell>
                  <CustomTableHeadCell>Products</CustomTableHeadCell>
                  <CustomTableHeadCell>Services</CustomTableHeadCell>
                  <CustomTableHeadCell>Action</CustomTableHeadCell>
                  {/* <CustomTableHeadCell>
                    <div className="flex justify-center">Enable/Disable</div>
                  </CustomTableHeadCell> */}
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {coupons.map((category: any) => {
                  return (
                    <CustomTableBodyRow key={category._id}>
                      <CustomTableBodyCell>
                        {category?.name}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {category?.type}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {category?.percentageDiscount + '%'}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {category?.vet?.fullname}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {category?.products
                          ?.map((product) => product.name)
                          .join(', ')}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {category?.services
                          ?.map((service) => service.name)
                          .join(', ')}
                      </CustomTableBodyCell>

                      <CustomTableHeadCell>
                        <button
                          onClick={() => {
                            setSelectedCoupon(category);
                            setEditCouponModal(true);
                          }}
                          className="text-white publicSans text-sm font-medium leading-5 bg-[#1F9CB9] py-1 px-2 rounded "
                        >
                          Edit
                        </button>
                      </CustomTableHeadCell>
                      {/* <CustomTableBodyCell>
                        <div className="flex justify-center">
                          <Switch
                            checked={category?.active}
                            onChange={(e) =>
                              handleEnableDisable(e, category?._id)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                            color="secondary"
                          />
                        </div>
                      </CustomTableBodyCell> */}
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
        <div className="w-full flex justify-between items-center p-4">
          <p className="publicSans text-sm font-medium text-[#667085] leading-5">
            {dataRange}
          </p>
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            color="secondary"
            onChange={(e, value) => setPage(value)}
          />
        </div>
      </div>
      <Modal
        open={addCouponModal}
        onClose={() => setAddCouponModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <AddCoupon
          setOpen={setAddCouponModal}
          checked={checked}
          setChecked={setChecked}
        />
      </Modal>
      <Modal
        open={editCouponModal}
        onClose={() => setEditCouponModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <EditCoupon
          setOpen={setEditCouponModal}
          checked={checked}
          setChecked={setChecked}
          existingData={selectedCoupon}
        />
      </Modal>
    </>
  );
};

export default CouponsTable;
